







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';

@Component
export default class BirthdateInput extends Vue {

  @Prop({ default: '' }) private value!: string;
  @Prop({ default: '' }) private label!: string;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default : true }) private required!: boolean;
  @Prop({ default : true }) private valid!: boolean;

  private birthdateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;

  private birthdateForm: ValidatedForm = new ValidatedForm({
    day: new FormField((value: any, dirty: boolean) =>
      /[0-2][0-9]|(3)[0-1]/.test(value) || !this.required && value === '', this.value.substring(0, 2)),
    month: new FormField((value: any, dirty: boolean) =>
      /(((0)[0-9])|((1)[0-2]))/.test(value) || !this.required && value === '', this.value.substring(3, 5)),
    year: new FormField((value: any, dirty: boolean) =>
      /\d{4}/.test(value) || !this.required && value === '', this.value.substring(6, 10)),
  });

  private updateBirthdate() {
    if (this.birthdateForm.getFormValid()) {
      const bfields = this.birthdateForm.fields;
      const bd = `${bfields.day.value}-${bfields.month.value}-${bfields.year.value}`;
      if (this.birthdateRegex.test(bd)) {
      this.$emit('input', bd);
      }
    }
  }

  private focusElement(ref: string) {
    (this.$refs[ref] as any).$el.focus();
  }

  get formValid() { return this.birthdateForm.fieldValidity(); }
}

